<style lang="scss">
.add-product-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
thead {
  position: sticky;
  width: 100%;
}
</style>

<template>
  <div class="add-product-modal">
    <section class="mb-2">
      <b-form-group
        label="Scan Date"
        label-for="update-group-notification"
        :rules="{ required: true}"
      >
        <validation-provider
          v-slot="validationContext"
          name="scheduled_for"
        >
          <flat-pickr
            v-model="scan.schedule_for"
            required
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:00'}"
          />
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
    </section>
    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="addScan()"
      >Add Scan</b-button>
    </section>
  </div>
</template>

<script>
  import ScansService from '@/services/ScansService'
  import flatPickr from 'vue-flatpickr-component'

  export default {
    components: {
      flatPickr,
    },
    props: {
      target: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        showPassword: false,
        scan: {
          schedule_for: '',
          target_uid: '',
        },
      };
    },
    mounted() {
    },
    methods: {
      addScan() {
        const s = this.scan.schedule_for;
        const d = new Date(s);
        this.scan.target_uid = this.target.uid;
        this.scan.schedule_for = d;
        ScansService.createScan(this.scan).then(() => {
          this.$toast.success(`Added Scan for ${this.target.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh');
          this.$emit('close');
        }).catch(() => {
          this.$toast.error('Could not add Scan, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const date = this.scan.schedule_for.length > 0;

        return date;
      },
    },
  };
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
