import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/vulnerability_scans';

export default {
  getScans(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createScan(scan) {
    return axios.post(`${baseUrl}`, scan);
  },
};
