<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="target-scans-page">

    <div class="row">
    </div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="card-header">
        <h4 class="card-title">Scans</h4>
        <section class="d-flex align-items-center justify-content-end">
          <b-button v-if="$can('Create', 'VulnerabilityScan')" @click="$bvModal.show('modal-add-scan')" class="btn-success pull-right"><span class="text-nowrap">Add Scan</span></b-button>
        </section>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative data-table"
        hover
        :items="scans"
        :fields="headers"
        :current-page="currentPage"
        :per-page="0"
      >
        <template #cell(created_at)="data">
          {{data.item.created_at | formatDateTime}}
        </template>
        <template #cell(scheduled_for)="data">
          {{data.item.scheduled_for | formatDateTime}}
        </template>

        <template #cell(completed_at)="data">
          {{data.item.completed_at | formatDateTime}}
        </template>
        <template #cell(low_severity)="data">
          <span v-if="data.item.low_severity > 0" class="badge badge-warning">
            {{data.item.low_severity}}
          </span>
        </template>
        <template #cell(medium_severity)="data">
          <span v-if="data.item.medium_severity > 0" class="badge badge-danger">
            {{data.item.medium_severity}}
          </span>
        </template>
        <template #cell(high_severity)="data">
          <span v-if="data.item.high_severity > 0" class="badge badge-dark">
            {{data.item.high_severity}}
          </span>
        </template>
      </b-table>
      <b-row>
        <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
          <b-pagination @change="getMoreScans" :total-rows="total_count" :per-page="10" v-model="currentPage" class="my-0"/>
        </b-col>
        <b-col class="ml-2 mt-1" v-if="total_count === 0">
          <p >No results</p>
        </b-col>
      </b-row>
    </b-card>

    <div class="d-flex mt-2">
      <div class="ui-spacer"></div>
    </div>
    <b-modal id="modal-add-scan" title="Add new Scan" size="lg" hide-footer>
      <add-scan :target="this.target" @refresh="refreshScans()" @close="closeModals()"></add-scan>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import ScansService from '@/services/ScansService';
import TargetService from '../../../services/TargetService';
import AddScan from './sections/AddScan.vue';

export default {
  name: 'Scans',
  components: {
    AddScan,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedProduct: null,
      loading: true,
      target: {
        uid: '',
        name: '',
        target: '',
        notification_group_uid: '',
        created_at: '',
      },
      scans: [],
      filters: {
        page: 0,
        page_size: 10,
      },
      targetArr: {
        target_uid: '',
      },
      headers: [
        {
          key: 'created_at',
          label: 'Created',
        },
        {
          key: 'scheduled_for',
          label: 'Scheduled',
        },
        {
          key: 'completed_at',
          label: 'Completed',
        },
        {
          key: 'low_severity',
          label: 'Low Severity',
          class: 'text-right',
        },
        {
          key: 'medium_severity',
          label: 'Medium Severity',
          class: 'text-right',
        },
        {
          key: 'high_severity',
          label: 'High Severity',
          class: 'text-right',
        },
      ],
      total_count: 0,
      currentPage: 1,
    };
  },
  mounted() {
    this.getTarget();
    this.getScans();
  },
  methods: {
    refreshTarget() {
      this.getTarget();
    },
    refreshScans() {
      this.getScans();
    },
    closeModals() {
      this.$bvModal.hide('modal-add-scan');
    },
    getTarget() {
      this.loading = true;
      TargetService.getTarget(this.uid)
        .then(res => {
          this.target = {
            name: res.data.name,
            uid: res.data.uid,
            notification_group_uid: res.data.notification_group_uid,
            target: res.data.target,
            created_at: res.data.created_at,
          }
          this.targetArr.target_uid = this.target.uid
        })
        .catch(() => {
          this.$toast.error('Could not get target, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getMoreScans(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at Notification Group1 lol
      this.getScans();
    },
    getScans() {
      this.loading = true;
      this.filters.page_size = 10;
      this.filters.target_uid = this.uid;
      ScansService.getScans(this.filters).then(res => {
        this.scans = res.data.data;
        this.filters.page = res.data.meta.page;
        this.scans.page_size = 10;
        this.total_count = res.data.meta.total_count;
        this.currentPage = res.data.meta.page + 1;
      }).catch(() => {
        this.$toast.error('Could not get scans, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
